import axios from 'axios';

let portalUrl = sessionStorage.getItem("portalUrl");
let portalId = sessionStorage.getItem("portalId");
const baseURL = portalUrl;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With' : portalId
      },
});


export default API;