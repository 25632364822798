import api from "../../api/guestapi";


const getGatepassById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/gid/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getBusinessInfo = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/business-info").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const appGuestServices = {
    getGatepassById,
    getBusinessInfo,
}
export default appGuestServices;