import api from "../../api/api";

const doCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/gatepass/scan/checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const doCheckout = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/gatepass/scan/checkout",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const getScanLog = (locationId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/gatepass/scan/log/"+locationId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}


const gatepassScanServices = {
    doCheckin,
    doCheckout,
    getScanLog
}
export default gatepassScanServices;