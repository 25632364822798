import React,{useState,useEffect,useRef} from 'react'
import { PersonAdd} from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom'
import AppLayout from '../../../layout/components/AppLayout'
import { useAlert } from '../../../context/AlertContext'
import useBusinessInfo from '../../../hooks/useBusinessInfo';
import { useAuth } from '../../../context/AuthContext';
import gatepassServices from '../gatepassServices';
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import { CircularProgress } from '@mui/material';
import A4GatePass from '../components/PrintStyles/A4GatePass';
import A5GatePass from '../components/PrintStyles/A5GatePass';
import Label3X6GatePass from '../components/PrintStyles/Label3X6GatePass';
import Label3X3GatePass from '../components/PrintStyles/Label3X3GatePass.js';




function GatepassPrintPage() {
    const {id} = useParams();
    const {AppAlert} = useAlert()
    const businessInfo = useBusinessInfo();
    const {authUser } = useAuth();
    const [masters,getMasterLabel] =  useSystemMasterList(["visit-purpose"]);
    const [gatepass,setGatepass] = useState(null);
    const [loading,setLoadingStatus] = useState(false);

    const [pageType,setPageType] = useState("3x6");
    

    const getGatepass = () =>{
        setGatepass(null);
        let gpid =  id;//gatepassInputId.replace(/\D/g,'').replace("-","")
        if(gpid !== ""){
            setLoadingStatus(true)
            gatepassServices.getById(gpid).then(res=>{
                setGatepass(res.data);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            }).finally(()=>{
                setLoadingStatus(false)
            })
        } 
    }

    useEffect(()=>{
        getGatepass()
    },[])

    
    
    return (
        <AppLayout>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-8'>
                    
                    </div>
                    <div className='col-4'>
                        {/* <Link to={"/gatepass/generate"} className='btn btn-primary float-end me-4'><PersonAdd/> Generate New Pass</Link> */}
                    </div>
                </div>
                {loading && <div className='text-center my-5'><CircularProgress/></div>}
                
                {gatepass && businessInfo && authUser && masters &&
                    <>
                        <ul className="nav nav-pills nav-tabs nav-fill my-3">
                            <li class="nav-item">
                                <button onClick={()=>setPageType("3x3")} className={pageType ==="3x3" ? "nav-link active" : "nav-link"} >Label 3x3</button>
                            </li>
                            <li class="nav-item">
                                <button onClick={()=>setPageType("3x6")} className={pageType ==="3x6" ? "nav-link active" : "nav-link"} >Label 3x6</button>
                            </li>
                            <li class="nav-item">
                                <button onClick={()=>setPageType("a5")} className={pageType ==="a5" ? "nav-link active" : "nav-link"} >A5 (Half Page)</button>
                            </li>
                            <li class="nav-item">
                                <button onClick={()=>setPageType("a4")} className={pageType ==="a4" ? "nav-link active" : "nav-link"}>A4 Size  (full Page)</button>
                            </li>
                        </ul>

                        
                        {pageType === "3x3" &&
                            <Label3X3GatePass
                                masters={masters}
                                getMasterLabel={getMasterLabel}
                                gatepass={gatepass}
                                businessInfo={businessInfo}
                                authUser={authUser}
                            />
                        }

                        {pageType === "3x6" &&
                            <Label3X6GatePass
                                masters={masters}
                                getMasterLabel={getMasterLabel}
                                gatepass={gatepass}
                                businessInfo={businessInfo}
                                authUser={authUser}
                            />
                        }

                        {pageType === "a5" &&
                            <A5GatePass
                                masters={masters}
                                getMasterLabel={getMasterLabel}
                                gatepass={gatepass}
                                businessInfo={businessInfo}
                                authUser={authUser}
                            />
                        }

                        {pageType === "a4" &&
                            <A4GatePass
                                masters={masters}
                                getMasterLabel={getMasterLabel}
                                gatepass={gatepass}
                                businessInfo={businessInfo}
                                authUser={authUser}
                            />
                        }

                        
                    </>
                }
            </div>
        </AppLayout>
    )
}

export default GatepassPrintPage