import React,{useState,useEffect,useRef} from 'react'
import { Download } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import AppLayout from '../../../layout/components/AppLayout'
import DateSelect from '../../../components/AppInput/DateSelect'
import gatepassServices from '../gatepassServices';



const RenderLink = (params) =>{
    return (
        <Link to={`/gatepass/print/${params.data.id}`}>{params.data.visitorName}</Link>
    )
}


function GatepassListPage() {
    const [filter,setFilter] = useState(null);
    const [reportData,setReportData] = useState(null);

    const getReportData = ()=>{
        if(filter!==null){
            gatepassServices.getReportData(filter).then(res=>{
                setReportData(res.data);
            }).catch(error=>{
            })
        }
    }

    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
        buttons: ['apply'],
        },
        resizable: true,
        width:150,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    });

    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        let _columnDefs = [
            {field:"id",headerName: "Reg No", width:90},
            {headerName:"Visitor Name", width:"200", cellRenderer: RenderLink},
            {field:"visitorPhone"},
            {field:"valid on ",headerName:"In Time ", valueGetter: params => moment(params.data.startDate).format("lll")},
            {field:"validateTill",headerName:"Valid Till ", valueGetter: params => moment(params.data.endDate).format("lll")},
            {field:"created_at",headerName:"Generated At ", valueGetter: params => moment(params.data.created_at).format("lll")},
        ];
        setColumnDef(_columnDefs);
    }
    
    useEffect(()=>{
        getReportData();
    },[filter])


    useEffect(()=>{
        prepairColumnDef()
    },[])


    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    };
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6'>
                    </div>
                    <div className='col-md-6'>
                        <span className='float-end'>
                            <DateSelect filter={filter} setFilter={setFilter} />
                            {reportData && <button type="button" onClick={onBtnExport} className="btn btn-primary ms-3 float-end"><Download/>  Export</button> }
                        </span>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                    <div className="ag-theme-alpine" style={{height:600}}>
                        <AgGridReact
                          ref={gridRef}
                          rowData={reportData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          animateRows={true}
                          onGridReady={getReportData}
                        >
                        </AgGridReact>
                    </div>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default GatepassListPage