import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAlert } from '../../../context/AlertContext';


axios.defaults.baseURL = "https://auth-k.gtoken.in/";


function SelectPortalPage() {
    const navigate = useNavigate();
    const {AppAlert}  = useAlert();

    const getPortal =() =>{
        if(localStorage.portalUrl != undefined) {
            navigate("/auth/login")
        } else {
        }
    }
    useEffect(()=>{
        getPortal()
    },[])

    const setAppPortal = async (portal) =>{
        localStorage.setItem("portalUrl",portal.url);
        localStorage.setItem("portalTitle",portal.title);
        localStorage.setItem("portalId",portal.portalId);
        //localStorage.setItem("notificationUrl",portal.notificationUrl);
        //navigate("/auth/login");
        window.location.reload();
    }

    const [portalId,setPortalId] = useState("");

    const [portalDetails,setPortalDetails] = useState(null);
    const [dialogStatus,setDialogStatus] = useState(false);

    const verifyLink = () =>{
        setPortalDetails(null);
        setDialogStatus(false);
        let _portalDetails = null;
        if(portalId == "local-demo1"){
            _portalDetails = {url:"http://localhost:8010",portalId:"local-demo1",title:"Local Demo1 @ 8010"}
        }
        if(portalId == "local-demo2"){
            _portalDetails = {url:"http://localhost:8010",portalId:"local-demo2",title:"Local Demo2 @ 8010"}
        }
        if(portalId == "global-demo1"){
            _portalDetails = {url:"https://gatepass-api.globalinc.co.in",portalId:"global-demo1",title:"Global Demo1"}
        }

        if(_portalDetails){
            setPortalDetails(_portalDetails);
            setDialogStatus(true);
        } else {
            AppAlert("Invalid Portal Id","error");
        }
        
/*
        
        axios.post("/portal/verify",{portalId:portalId}).then(res=>{
            //console.log(res.data);
            if(res.data.error){
                AppAlert(res.data.message,"error");
            } else {
                setPortalDetails(res.data.data);
                setDialogStatus(true);
            }
        }).catch(error=>{
            AppAlert("Something went wrong",error);
        })

*/
        
    }

    

    return (
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className='col-md-12 text-center' >
                    <img src='/images/gatepass.jpg' style={{width:"30%"}} />
                    <img src='/globalinc.png' style={{width:"30%"}} />
                </div>
                <div className="col-md-12 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <span className="uniworldvision-logo"></span>
                                </div>
                            </div>
                            <div className="row mt-4">

                                <div className="col-md-12">
                                    <input type="text" className="form-control" placeholder="Please Enter Business Portal Id"
                                        value={portalId}
                                        onChange={(e)=>setPortalId(e.target.value)}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="card-footer">
                            <span className="float-end">
                                <button type="button" className="btn btn-danger" onClick={verifyLink} >Verify</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle></DialogTitle>
                {portalDetails &&
                    <DialogContent>
                        <h3>You Choose</h3>
                        <h4> {portalDetails.title} </h4>
                    </DialogContent>
                }
                <DialogActions>
                    <button type="button" className="btn btn-light" onClick={()=>setDialogStatus(false)} >Close</button>
                    <button type="button" className="btn btn-success" onClick={()=>setAppPortal(portalDetails)} >Continue</button>
                </DialogActions>
            </Dialog>
            

        </div>
    )
}

export default SelectPortalPage