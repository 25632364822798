import React, { useState } from 'react'
import gatepassScanServices from '../gatepassScanServices';
import { AccessTime, Add, CalendarMonth, Search } from '@mui/icons-material';
import moment from 'moment';

export default function GatepassCheckinLog({gatepassId}) {

    const [gatepassMovement,setGatepassMovement] = useState(null);

    const getGatepassMovement = () =>{
        gatepassScanServices.getScanLog(gatepassId).then(res=>{
            setGatepassMovement(res.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <>
            <button className='btn btn-primary mt-2' type='button' onClick={getGatepassMovement} >Show Movement</button>
            {gatepassMovement &&
            <ul className="timeline mt-3">
                {gatepassMovement.map(movement=>
                    <li key={movement.id} className="timeline-item" >
                    <span className='badge bg-dark'><CalendarMonth/> {moment(movement.created_at).format("DD-MMM YYYY") }</span>
                    <span className='badge bg-dark ms-2'><AccessTime/> {moment(movement.created_at).format("hh:mm A") }</span>
                    <div className='card border-0 mt-1 mb-3'>
                        <div className='card-body'>{movement.scanStatus === 1 ? "Checked in at" : "Checked out from "} <span className='fw-bold'>"{movement.locationTitle}"</span></div>
                    </div>
                </li>
                )}
            </ul>
            }
        </>
    )
}
