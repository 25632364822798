import React from 'react'
import { Route, Routes } from 'react-router'
import AppGuestDashboardPage from '../app/appGuest/pages/AppGuestDashboardPage'
import AppGuestGatepassPage from '../app/appGuest/pages/AppGuestGatepassPage'
import AuthRoutes from './AuthRoutes'
import SelectPortalPage from '../app/auth/pages/SelectPortalPage'
import LoginPage from '../app/auth/pages/LoginPage'





function AppGusetRoutes() {
  return (
    <>
        <Routes>
            <Route path="/" element={<LoginPage/>}></Route>
            <Route path="/portal/:portalId/:gid" element={<AppGuestGatepassPage/>}></Route>
        </Routes>
        <AuthRoutes/>
    </>
  )
}

export default AppGusetRoutes