import React,{useState,useEffect} from 'react'
import { QrReader } from 'react-qr-reader';
import { Dialog, DialogContent } from '@mui/material';
import { CameraAlt, Cameraswitch, Label } from '@mui/icons-material';


function SearchByQrCode({setScannedJson} ) {

    //const [scannedData, setScannedData] = useState('{"gatepassId":100001,"attendantId":2,"toMeet":"Manager Office","accessLocations":[{"id":1,"title":"Admin Block"},{"id":3,"title":"2nd Floor"}],"visitorName":"Amit ","visitorPhoto":"http://localhost:8010/uploads/local-demo1/gatepass/visitor/2_1731740871.","startDate":"2024-11-16 12:37:53","endDate":"2024-11-16 16:37:53","gatepassAppId":"as1289787gh"}');
    //const [scannedData, setScannedData] = useState('[100001,10001]');
    const [scannedData, setScannedData] = useState(null);
    const [dialogStatus,setDialogStatus] = useState(false);
    const [facingMode,setFacingMode] = useState(true);
    const [scannedInputValue,setScannedInputValue] = useState("");

    const togleCamera = () => {
        if(facingMode === true){
            setFacingMode(false)
        } else {
            setFacingMode(true)
        }
    }
    
    useEffect(()=>{
        if(scannedData!==null){
            try{
                setScannedJson(JSON.parse(scannedData))
            } catch (error){
                setScannedJson(null);
            }
            
        }
    },[scannedData])

    
    const handleEnter = ()=>{
        
        if(scannedInputValue !== ""){
            try{
                let _scannedJson = JSON.parse(scannedInputValue)
                if(_scannedJson && _scannedJson?.gId !== undefined){
                    console.log("here in ");
                    console.log(_scannedJson)
                    setScannedJson(_scannedJson)
                } else{
                    setScannedJson(null);
                }
                
            }catch (error){
                setScannedJson(null)
            }
            setScannedInputValue("");
        }
    }




  return (
    <>
            <div className="input-group mb-3">
                <input type='text' 
                    className='form-control'
                    placeholder='Scan here with device'
                    value={scannedInputValue}
                    onChange={(e)=>{
                        setScannedInputValue(e.target.value)
                    }}
                    onKeyUp={ (e) =>{
                        if(e.key === 'Enter') {
                            e.preventDefault();
                            handleEnter();
                        }
                    }}
                    ref={input => input && input.focus()}
                />
                <button className='btn btn-primary' onClick={()=>setDialogStatus(true)}><CameraAlt/> Scanner</button>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogContent>
                    {dialogStatus && facingMode &&
                        <QrReader
                            constraints={ {facingMode: "environment"} }
                            onResult={(result, error) => {
                            if (!!result) {
                                //console.log(result);
                                setScannedData(result?.text);
                                setDialogStatus(false);
                            }

                            if (!!error) {
                                console.info(error);
                            }
                            }}
                            style={{ width: '100%' }}
                            
                        />
                    }

                    {dialogStatus && !facingMode &&
                        <QrReader
                            onResult={(result, error) => {
                            if (!!result) {
                                //console.log(result);
                                setScannedData(result?.text);
                                setDialogStatus(false);
                            }

                            if (!!error) {
                                console.info(error);
                            }
                            }}
                            style={{ width: '100%' }}
                            
                        />
                    }
                    <div className='text-center'>
                        <button className='btn btn-outline-primary mx-3' onClick={togleCamera}> <Cameraswitch/> </button>
                        <button className='btn btn-outline-primary mx-3' onClick={()=>setDialogStatus(false)}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>
    </>
  )
}

export default SearchByQrCode