import { WhatsApp } from '@mui/icons-material'
import React, { useState } from 'react'

function WhatsAppShare({gatepass}) {

    const [link,setLink] = useState(null);


    useState(()=>{
        let portalUrl = window.location.origin;
        let portalId = localStorage.getItem("portalId");
        let _link = "https://api.whatsapp.com/send/?phone=91"+gatepass.visitor.phone+"&text="+portalUrl+"/portal/"+portalId+"/"+gatepass.id;
        setLink(_link)

        
    },[gatepass])

  return (
    <a target='_blank' href={link} className='btn btn-outline-success'><WhatsApp/> Share on WhatsApp</a>
  )
}

export default WhatsAppShare