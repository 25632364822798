

const setGuestPortal = (portal) =>{
    sessionStorage.setItem("portalUrl",portal.url);
    sessionStorage.setItem("portalTitle",portal.title);
    sessionStorage.setItem("portalId",portal.portalId);
    window.location.reload();
}


const setupGuestPortal = (portalId) =>{
    let sessionPortalId = sessionStorage.getItem("portalId")
    if(sessionPortalId == null || sessionPortalId !== portalId){
        let _portalDetails = null;
        if(portalId == "local-demo1"){
            _portalDetails = {url:"http://localhost:8010",portalId:"local-demo1",title:"Local Demo1 @ 8010"}
        }
        if(portalId == "global-demo1"){
            _portalDetails = {url:"https://gatepass-api.globalinc.co.in",portalId:"global-demo1",title:"Global Demo1"}
        }
        setGuestPortal(_portalDetails);
    }
    /*
    axios.post("/portal/verify",{portalId:portalId}).then(res=>{
        if(res.data.error){
            console.log("No Portal found")
        } else {
            localStorage.setItem("portalId",portalId);
            localStorage.setItem("portalUrl",res.data.data.url);
            localStorage.setItem("portalTitle",res.data.data.title);
            localStorage.setItem("notificationUrl",res.data.data.notificationUrl);
            window.location.reload();
        }
    }).catch(error=>{    
    })s
    */
}


const appGuestPortalServices = {
    setupGuestPortal,
}
export default appGuestPortalServices;