import React,{useState,useEffect} from 'react'
import gatepassServices from '../gatepassServices'
import LabelValue from './PrintStyles/LabelValue'
import moment from 'moment'
import GatepassCheckinLog from './GatepassCheckinLog'

function GatepassCheckinInfo({gatepassId}) {
    const [gatepass,setGatepass]= useState(null)
    useEffect(()=>{
        setGatepass(null)
        gatepassServices.getById(gatepassId).then(res=>{
            setGatepass(res.data);
        }).catch(error=>{
        })
    },[])

    return (
        <>
            { gatepass  &&
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='card mt-3'>
                            <img src={gatepass.visitor.photo === null ? "/images/blank-profile.png"  :gatepass.visitor.photo } className="card-img-top" alt={gatepass.visitorName} />
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <LabelValue label={"Name"} value={gatepass.visitor.visitorName} />
                                    </div>
                                    <div className='col-md-12'>
                                        <span className='fw-bold'>Allowed Location : </span>
                                        {gatepass.accessLocationList.map(location=>
                                            <span key={location.id} className="me-1">{location.title},</span>
                                        )}
                                    </div>
                                    <div className='col-12 text-center'>
                                        <div className=''>Valid from : 
                                            <strong> {moment(gatepass.startDate).format("lll")}</strong> to <strong>{moment(gatepass.endDate).format("lll")} </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <GatepassCheckinLog gatepassId={gatepassId} />
                    </div>
                </div>
            }
        </>
  )
}

export default GatepassCheckinInfo