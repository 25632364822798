import React from 'react'
import { Route, Routes } from 'react-router'
import AdminLandingPage from '../app/admin/dashboard/pages/AdminLandingPage'
import GenerateGatepassPage from '../app/gatepass/pages/GenerateGatepassPage'
import GatepassPrintPage from '../app/gatepass/pages/GatepassPrintPage'
import GatepassListPage from '../app/gatepass/pages/GatepassListPage'
import GatepassScanCheckin from '../app/gatepass/pages/GatepassScanCheckin'
import LocationManagerPage from '../app/admin/LocationManager/pages/LocationManagerPage'
import GateUserRoutes from './GateUserRoutes'
import AppGuestGatepassPage from '../app/appGuest/pages/AppGuestGatepassPage'


function ManagerRoutes() {
  return (
    <>
      <Routes>
        {/* Admin */}
        <Route path="/" element={ <AdminLandingPage /> }></Route>
        <Route path="/gatepass/generate" element={ <GenerateGatepassPage /> }></Route>
        <Route path="/gatepass/print/:id" element={ <GatepassPrintPage /> }></Route>
        <Route path="/gatepass/list" element={ <GatepassListPage /> }></Route>
        <Route path="/location/manager" element={ <LocationManagerPage /> }></Route>


        <Route path="/portal/:portalId/:gid" element={<AppGuestGatepassPage/>}></Route>
      </Routes>
      <GateUserRoutes/>
    </>
  )
}

export default ManagerRoutes