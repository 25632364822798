import React,{useEffect, useState} from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import LabelValue from '../components/PrintStyles/LabelValue';
import moment from 'moment';
import gatepassServices from '../gatepassServices';
import SearchByQrCode from '../components/SearchByQrCode';
import GatepassCheckinInfo from '../components/GatepassCheckinInfo';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext'
import { Switch } from '@mui/material';
import gatepassScanServices from '../gatepassScanServices';
import { Login, Logout } from '@mui/icons-material';

function GatepassScanCheckin() {
    const {authUser } = useAuth();
    const {AppAlert} = useAlert();
    const [scannedJson,setScannedJson] = useState(null)
    const successSound = new Audio("/success.mp3");
    const errorSound1 = new Audio("/error1.mp3")
    const errorSound2 = new Audio("/error2.mp3")
    const [errorMsg,setErrorMessage] = useState(null);
    const [success,setSuccess] = useState(false);

    const [autoCheckin,setAutocheckinStatus] = useState(false);

    const validateGatepass = () =>{
        let curretTimeStamp = moment().format("X");
        console.log(curretTimeStamp)
        console.log(scannedJson);
        setErrorMessage(null);
        setSuccess(false);
        if(curretTimeStamp >= scannedJson.s &&  curretTimeStamp <= scannedJson.e){    
            if(scannedJson.accessLocations.includes(authUser.locationId)){
                successSound.play();
                setSuccess(true);
                if(autoCheckin){
                    doCheckin()
                }
            } else{
                errorSound2.play();
                console.log("this is gate number ",authUser.locationId)
                setErrorMessage("Wrong Gate ")
            }
        } else{
            errorSound1.play();
            console.log(curretTimeStamp ," >= ", scannedJson.startDate)
            setErrorMessage("Gatepass Expired")
            return false;
        }
    }

    useEffect(()=>{
        if(scannedJson!== null){
            validateGatepass()    
        }
    },[scannedJson]);

    const doCheckin = () =>{
        let payload  = {
            gatepassId:scannedJson.gId,
            locationId:authUser.locationId
        }
        gatepassScanServices.doCheckin(payload).then(res=>{
            AppAlert("Checked In Successfully");
            setScannedJson(null);
            setSuccess(false)
        }).catch(error=>{
            console.log(error)
        })
    }

    const doCheckout = () =>{
        let payload  = {
            gatepassId:scannedJson.gId,
            locationId:authUser.locationId
        }
        
        gatepassScanServices.doCheckout(payload).then(res=>{
            AppAlert("Checked out Successfully");
            setScannedJson(null);
            setSuccess(false)
        }).catch(error=>{
            console.log(error)
        })
    }
    
    return (
        <AppLayout>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-9'>
                        <SearchByQrCode setScannedJson={setScannedJson } />
                    </div>
                    {/*
                    <div className='col-md-3'>
                        <span className=''>Auto Checkin </span>
                        <span><Switch checked={autoCheckin} onChange={(event, checked) =>setAutocheckinStatus(event.target.checked)} /></span>
                    </div>
                    */}
                </div>
                    {errorMsg !== null &&
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="alert alert-danger" role="alert">
                                    <h4 class="alert-heading">Not Allowed!</h4>
                                    <p>{errorMsg}</p>
                                </div>
                                {scannedJson !== null && <GatepassCheckinInfo gatepassId={scannedJson.gId} />}
                            </div>
                        </div>
                    }
                    {success &&
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="alert alert-success" role="alert">
                                    <h4 class="alert-heading">Success !</h4>
                                    {autoCheckin === false && <button className='btn btn-success' onClick={doCheckin} > <Login/> Do Checkin</button>}
                                    {autoCheckin === false && <button className='btn btn-danger ms-2' onClick={doCheckout} >Do Checkout <Logout/> </button>}
                                </div>
                                {autoCheckin == false && scannedJson !== null && <GatepassCheckinInfo gatepassId={scannedJson.gId} />}
                            </div>
                            <div className='col-md-6'>
                                
                            </div>
                        </div>
                    }
            </div>
        </AppLayout>
    )
}

export default GatepassScanCheckin