import React from 'react'
import { Route, Routes } from 'react-router'
import GatepassScanCheckin from '../app/gatepass/pages/GatepassScanCheckin'
import { useAuth } from '../context/AuthContext'


function GateUserRoutes() {
    const {authUser} = useAuth()
    return (
        <>
        <Routes>
            {/* Gate User */}
            {authUser.role == 3  && <Route path="/" element={ <GatepassScanCheckin /> }></Route> }
            <Route path="/gatepass/scan" element={ <GatepassScanCheckin /> }></Route>
        </Routes>
        </>
    )
}

export default GateUserRoutes